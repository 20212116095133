<template>
  <div>
    <div v-if="loading" class="d-flex justify-content-center mb-1">
      <b-spinner label="Loading..." />
    </div>

    <div v-else>
      <div v-if="trialExamForResults">
        <b-button
          size="sm"
          class="mb-1"
          variant="outline-primary"
          @click="trialExamForResults = null"
        >
          <feather-icon icon="ArrowLeftIcon" class="mr-50" />
          <span class="align-middle">Sınavlara Dön</span>
        </b-button>
        <exam-result :trialExam="trialExamForResults"></exam-result>
      </div>
      <div v-else>
        <b-card title="TUS Denemeleri" style="min-height: 240px">
          <b-table
            responsive
            selectable
            hover
            :per-page="examsPerPage"
            :current-page="examsCurrentPage"
            select-mode="single"
            :items="trialExams"
            :fields="examsTableFields"
            @row-selected="onExamSelected"
          >
            <template #cell(question_count)="data">
              {{ data.item.current_question_count }} / {{ data.value }}
            </template>

            <template #cell(published)="data">
              <div :style="data.value == 1 ? 'color: green;' : 'color:red;'">
                {{ data.value == 1 ? "Yayınlandı" : "Yayında Değil" }}
              </div>
            </template>

            <template #cell(condition)="data">
              <div
                :style="
                  'font-weight: bold; ' +
                  (data.item.published == 1
                    ? data.item.tus_analiz_exam == 1
                      ? 'color: #1E60FF;'
                      : 'color: green;'
                    : 'color: red;')
                "
              >
                {{
                  data.item.published == 1
                    ? data.item.tus_analiz_exam == 1
                      ? "TUS Analize Özel"
                      : "Tüm Kullanıcılar"
                    : "Yayında Değil"
                }}
              </div>
            </template>

            <template #cell(results)="data">
              <b-button
                v-if="data.item.participant_count"
                size="sm"
                variant="outline-primary"
                @click="showExamResult(data)"
              >
                <span class="align-middle">Göster</span>
                <feather-icon icon="ArrowRightIcon" class="ml-50" />
              </b-button>
            </template>
          </b-table>

          <div class="mt-1">
            <b-pagination
              v-model="examsCurrentPage"
              :total-rows="examsTotalRows"
              :per-page="examsPerPage"
              :align="'center'"
              class="mb-2"
            />
            <b-button
              variant="outline-primary"
              @click="showAddExamCard()"
              class="mx-1"
              style="float: right"
            >
              Yeni Sınav
            </b-button>
          </div>
        </b-card>

        <b-card
          v-if="addExamMode || selectedExam"
          :title="
            selectedExam
              ? selectedExam.exam_name + ' (Id: ' + selectedExam.te_id + ')'
              : 'Yeni Sınav Ekle'
          "
          style="min-height: 240px"
        >
          <b-row>
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Sınav Adı" label-for="examName">
                <b-form-input
                  id="examName"
                  v-model="examItemName"
                  placeholder="Eylül-1"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Soru Sayısı" label-for="questioncount">
                <b-form-input
                  id="questioncount"
                  v-model="examItemQuestionCount"
                  placeholder="Eylül-1"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Süre (dk)" label-for="durationInMins">
                <b-form-input
                  id="durationInMins"
                  v-model="examItemDuration"
                  placeholder="Eylül-1"
                />
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group
                label="Yayınlanma Durumu"
                label-for="examItemCondition"
              >
                <v-select
                  id="examItemCondition"
                  v-model="examItemCondition"
                  :options="examConditionOptions"
                  :filterBy="filterBy"
                  :clearable="false"
                  :disabled="selectedExam == null"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6" class="mb-1">
              <b-form-group
                label="Temel bilimler başlangıç uyarısı"
                label-for="basicDesc"
              >
                <b-form-textarea
                  id="basicDesc"
                  v-model="basicPartDescription"
                  placeholder=""
                  rows="2"
                  max-rows="8"
                  class="mb-1 mb-xl-0"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" class="mb-1">
              <b-form-group
                label="Klinik bilimler başlangıç uyarısı"
                label-for="clinicalDesc"
              >
                <b-form-textarea
                  id="clinicalDesc"
                  v-model="clinicalPartDescription"
                  placeholder=""
                  rows="2"
                  max-rows="8"
                  class="mb-1 mb-xl-0"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-alert variant="warning" show class="mb-2">
            <div class="alert-body">
              <strong>Dikkat!: </strong>
              Yayınlanma Durumu 'Yayınlanmadı' olarak seçilmeyen tüm deneme
              sınavları kullanıcılar tarafından görüntülenebilmektedir ve
              katılıma açıktır!
            </div>
          </b-alert>

          <div v-if="selectedExam" class="mt-1">
            <b-button
              v-if="selectedExam.participant_count == 0"
              variant="outline-danger"
              v-b-modal.modal-delete-exam
              class="mx-1"
              style="float: right"
            >
              Sınavı Sil
            </b-button>

            <b-button
              variant="outline-primary"
              @click="updateSelectedExam()"
              class="mx-1"
              style="float: right"
            >
              Bilgileri Güncelle
            </b-button>
          </div>

          <div v-else class="mt-1">
            <b-button
              variant="primary"
              @click="addNewExam()"
              class="mx-1"
              style="float: right"
            >
              Sınavı Ekle
            </b-button>
          </div>

          <b-modal
            id="modal-delete-exam"
            ref="modal-delete-exam"
            hide-footer
            centered
            title="Sınavı Sil"
          >
            <div v-if="selectedExam">
              <p class="m-1">
                {{ selectedExam.exam_name }} tamamen silinecek. Bu işlem geri
                alınamaz!
              </p>

              <hr style="margin: 20px 10px" />
              <div>
                <b-button
                  class="mr-1"
                  style="float: right"
                  variant="primary"
                  @click="$refs['modal-delete-exam'].hide()"
                >
                  Kapat
                </b-button>
                <b-button
                  class="mr-1"
                  style="float: right"
                  variant="danger"
                  @click="deleteSelectedExam()"
                >
                  Sil
                </b-button>
              </div>
            </div>
          </b-modal>
        </b-card>

        <b-row class="match-height">
          <b-col v-if="selectedExam" md="6">
            <b-card
              v-if="selectedExam"
              :title="
                selectedExam.exam_name +
                ' / Sorular (' +
                selectedExam.current_question_count +
                ')'
              "
              style="min-height: 240px"
            >
              <b-tabs>
                <b-tab title="Sorular">
                  <b-form-input
                    v-model="questionListFilter"
                    placeholder="Soru Ara"
                    autocomplete="off"
                  />

                  <b-table
                    ref="examQuestionsTable"
                    responsive
                    selectable
                    hover
                    select-mode="single"
                    class="mt-1"
                    :per-page="questionsPerPage"
                    :current-page="questionsCurrentPage"
                    :items="questionsInOrderOfSelectedExam"
                    :fields="examQuestionsTableFields"
                    @row-selected="onQuestionSelected"
                  >
                    <template #cell(order)="data">
                      <div>
                        {{ data.value }}
                      </div>
                      <b-badge v-if="data.item.canceled" variant="warning">
                        İptal
                      </b-badge>
                    </template>
                    <template #cell(title)="data">
                      {{ toTitleCase(data.value) }}
                    </template>
                    <template #cell(subtitle)="data">
                      {{ toTitleCase(data.value) }}
                    </template>
                    <template #cell(question_text)="data">
                      <p
                        class="max-one-line"
                        style="margin-bottom: 0px; max-width: 750px"
                      >
                        {{ data.value }}
                      </p>
                    </template>
                  </b-table>

                  <div class="mt-1">
                    <b-pagination
                      v-model="questionsCurrentPage"
                      :total-rows="questionssTotalRows"
                      :per-page="questionsPerPage"
                      :align="'center'"
                      class="mb-1"
                    />
                  </div>

                  <b-button
                    v-if="questionsInOrderOfSelectedExam.length == 0"
                    variant="outline-warning"
                    @click="$refs['modal-transfer-exam-questions'].show()"
                    class="mx-1"
                    style="float: right"
                  >
                    Soruları Başka Bir Sınavdan Aktar
                  </b-button>
                </b-tab>
                <b-tab title="Soru Dağılımı">
                  <b-row class="mx-1 my-2">
                    <b-col md="4" class="">
                      <p
                        v-for="subject in tQuestionDistribution"
                        :key="subject.title"
                        class="font-weight-bold"
                      >
                        {{ subject.title }}: {{ subject.count }}
                      </p>
                    </b-col>
                    <b-col md="4" class="">
                      <p
                        v-for="subject in kQuestionDistribution"
                        :key="subject.title"
                        class="font-weight-bold"
                      >
                        {{ subject.title }}: {{ subject.count }}
                      </p>
                    </b-col>
                    <b-col md="4" class="">
                      <p style="font-weight: 700">CEVAP DAĞILIMI</p>
                      <p>
                        A:
                        <strong>{{ getOptionAnswerCount("A") }} </strong> soru
                      </p>
                      <p>
                        B:
                        <strong>{{ getOptionAnswerCount("B") }} </strong> soru
                      </p>
                      <p>
                        C:
                        <strong>{{ getOptionAnswerCount("C") }} </strong> soru
                      </p>
                      <p>
                        D:
                        <strong>{{ getOptionAnswerCount("D") }} </strong> soru
                      </p>
                      <p>
                        E:
                        <strong>{{ getOptionAnswerCount("E") }} </strong> soru
                      </p>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>

          <b-col :md="selectedExam ? 6 : 12">
            <b-card :title="'Soru Havuzu (' + poolQuestions.length + ')'">
              <b-row>
                <b-col md="6" class="mb-1">
                  <v-select
                    v-model="selectedSubject"
                    :clearable="false"
                    :options="subjectList"
                    label="title"
                  >
                    <template #option="data">
                      <span> {{ data.title }}</span>
                      <span>
                        ({{ getTitleQuestionCount(data.title_id) }} Soru)</span
                      >
                    </template>
                  </v-select>
                </b-col>

                <b-col md="6" class="mb-1">
                  <b-form-input
                    v-model="questionFilter"
                    placeholder="Soru içeriği filtreleyin"
                    autocomplete="off"
                  />
                </b-col>
              </b-row>

              <b-table
                ref="poolQuestionsTable"
                responsive
                selectable
                hover
                :per-page="questionPoolPerPage"
                :current-page="questionPoolCurrentPage"
                :items="questionsInTable"
                :fields="questionPoolTableFields"
                style="min-height: 160px"
                select-mode="single"
                @row-selected="onQuestionSelected"
              >
                <template #cell(title)="data">
                  {{ toTitleCase(data.value) }}
                </template>
                <template #cell(subtitle)="data">
                  {{ toTitleCase(data.value) }}
                </template>
                <template #cell(question_text)="data">
                  <p class="max-two-lines" style="margin-bottom: 0px">
                    {{ data.value }}
                  </p>
                </template>
                <template #cell(action)="data">
                  <b-button
                    v-if="selectedExam"
                    variant="outline-primary"
                    @click="transferQuestionToTrialExamFromPool(data.item)"
                    size="sm"
                    style="min-width: 80px"
                  >
                    Denemeye Aktar
                  </b-button>
                </template>
              </b-table>

              <div class="mt-1">
                <b-pagination
                  v-if="questionsInTable.length > questionPoolPerPage"
                  v-model="questionPoolCurrentPage"
                  :total-rows="questionsInTable.length"
                  :per-page="questionPoolPerPage"
                  :align="'center'"
                />
              </div>
            </b-card>
          </b-col>
        </b-row>

        <b-card style="min-height: 240px">
          <div class="mt-1" style="display: flex">
            <h4 class="flex-grow-1">
              {{ selectedQuestion ? "Soruyu Düzenle" : "Yeni Soru Ekle" }}
            </h4>
            <b-button
              v-if="selectedQuestion"
              variant="outline-secondary"
              @click="showAddQuestion()"
              size="sm"
              style="float: right"
            >
              Düzenlemeyi Bitir
            </b-button>
          </div>

          <div>
            <b-row>
              <b-col xl="6" cols="12">
                <label for="question-text">Soru Metni:</label>
                <b-form-textarea
                  id="question-text"
                  placeholder="Soru metnini yazın"
                  rows="2"
                  max-rows="8"
                  class="mb-1 mb-xl-0"
                  v-model="questionText"
                />

                <div class="mt-1" style="display: flex">
                  <div class="mr-2">
                    <b-button
                      :variant="answer == 'A' ? 'primary' : 'outline-secondary'"
                      @click="answer = 'A'"
                      class="btn-icon rounded-circle"
                    >
                      A
                    </b-button>
                  </div>
                  <div style="width: 100%">
                    <b-form-input
                      v-model="optionA"
                      placeholder=""
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div class="mt-1" style="display: flex">
                  <div class="mr-2">
                    <b-button
                      :variant="answer == 'B' ? 'primary' : 'outline-secondary'"
                      @click="answer = 'B'"
                      class="btn-icon rounded-circle"
                    >
                      B
                    </b-button>
                  </div>
                  <div style="width: 100%">
                    <b-form-input
                      v-model="optionB"
                      placeholder=""
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div class="mt-1" style="display: flex">
                  <div class="mr-2">
                    <b-button
                      :variant="answer == 'C' ? 'primary' : 'outline-secondary'"
                      @click="answer = 'C'"
                      class="btn-icon rounded-circle"
                    >
                      C
                    </b-button>
                  </div>
                  <div style="width: 100%">
                    <b-form-input
                      v-model="optionC"
                      placeholder=""
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div class="mt-1" style="display: flex">
                  <div class="mr-2">
                    <b-button
                      :variant="answer == 'D' ? 'primary' : 'outline-secondary'"
                      @click="answer = 'D'"
                      class="btn-icon rounded-circle"
                    >
                      D
                    </b-button>
                  </div>
                  <div style="width: 100%">
                    <b-form-input
                      v-model="optionD"
                      placeholder=""
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div class="mt-1" style="display: flex">
                  <div class="mr-2">
                    <b-button
                      :variant="answer == 'E' ? 'primary' : 'outline-secondary'"
                      @click="answer = 'E'"
                      class="btn-icon rounded-circle"
                    >
                      E
                    </b-button>
                  </div>
                  <div style="width: 100%">
                    <b-form-input
                      v-model="optionE"
                      placeholder=""
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div class="mt-1" style="display: flex">
                  <div class="mr-2" style="width: 100px; margin-top: 8px">
                    Alan Seçin:
                  </div>
                  <div style="width: 100%">
                    <b-form-group>
                      <v-select
                        v-model="selectedFieldText"
                        :options="tusFieldOptions"
                        :filterBy="filterBy"
                      />
                    </b-form-group>
                  </div>
                </div>

                <label class="mt-2" for="solution-text">Çözüm/Açıklama:</label>
                <b-form-textarea
                  id="solution-text"
                  placeholder="Soru metnini yazın"
                  rows="8"
                  max-rows="15"
                  class="mb-1 mb-xl-0"
                  v-model="solution"
                />

                <b-row v-if="uploadedImageUrls" class="match-height mt-2">
                  <b-col
                    v-for="imageUrl in uploadedImageUrls"
                    :key="imageUrl"
                    lg="6"
                  >
                    <b-img
                      :src="imageUrl"
                      fluid
                      alt="..."
                      style="height: 240px; object-fit: contain"
                    />
                    <b-form-input
                      readonly
                      :value="'[[' + imageUrl + ']]'"
                      class="mt-1"
                    />
                  </b-col>
                </b-row>

                <b-form-file
                  id="file-select"
                  class="mt-2"
                  browse-text="Fotograf Seç"
                  v-model="newImage"
                  placeholder="Bir fotograf seçin veya buraya sürükleyin..."
                  accept="image/jpeg, image/jpg, image/png"
                />

                <div v-if="newImage" class="mt-1">
                  <b-card-text class="my-1" style="float: left">
                    Seçili fotograf:
                    <strong>{{ newImage ? newImage.name : "" }}</strong>
                  </b-card-text>
                  <b-button
                    variant="outline-primary"
                    @click="uploadNewImage()"
                    size="sm"
                    class="mt-1"
                    style="float: right"
                  >
                    Yükle
                  </b-button>
                </div>

                <div class="mt-3" style="display: flex">
                  <div class="flex-grow-1"></div>
                  <b-button
                    variant="outline-warning"
                    pill
                    @click="pasteCopiedQuestion()"
                    size="sm"
                  >
                    Kopyalanan Soruyu Yapıştır
                  </b-button>
                </div>
              </b-col>

              <b-col xl="6" cols="12">
                <b-card title="Soru önizleme">
                  <b-card-text style="white-space: pre-wrap">
                    <div
                      v-html="
                        '<strong>Soru:</strong> ' +
                        getHtmlWithImage(questionText)
                      "
                    ></div>
                  </b-card-text>
                  <b-card-text style="white-space: pre-wrap">
                    <p
                      :class="
                        answer == 'A' ? 'font-weight-bolder text-primary' : ''
                      "
                    >
                      <strong>A:</strong> {{ optionA }}
                    </p>
                  </b-card-text>
                  <b-card-text style="white-space: pre-wrap">
                    <p
                      :class="
                        answer == 'B' ? 'font-weight-bolder text-primary' : ''
                      "
                    >
                      <strong>B:</strong> {{ optionB }}
                    </p>
                  </b-card-text>
                  <b-card-text style="white-space: pre-wrap">
                    <p
                      :class="
                        answer == 'C' ? 'font-weight-bolder text-primary' : ''
                      "
                    >
                      <strong>C:</strong> {{ optionC }}
                    </p>
                  </b-card-text>
                  <b-card-text style="white-space: pre-wrap">
                    <p
                      :class="
                        answer == 'D' ? 'font-weight-bolder text-primary' : ''
                      "
                    >
                      <strong>D:</strong> {{ optionD }}
                    </p>
                  </b-card-text>
                  <b-card-text style="white-space: pre-wrap">
                    <p
                      :class="
                        answer == 'E' ? 'font-weight-bolder text-primary' : ''
                      "
                    >
                      <strong>E:</strong> {{ optionE }}
                    </p>
                  </b-card-text>
                  <b-card-text style="white-space: pre-wrap" class="mt-2">
                    <p>
                      <strong>Alan: </strong>
                      {{ toTitleCase(selectedFieldText) }}
                    </p>
                  </b-card-text>

                  <b-card-text class="mt-3" style="white-space: pre-wrap">
                    <div
                      v-html="
                        '<strong>Çözüm / Açıklama</strong> <hr/>' +
                        getHtmlWithImage(solution)
                      "
                    ></div>
                  </b-card-text>

                  <div v-if="selectedQuestion" class="mt-3">
                    <div style="display: flex; justify-content: flex-end">
                      <b-button
                        variant="outline-primary"
                        @click="updateSelectedQuestion()"
                        class="mx-1"
                      >
                        Soruyu Düzenle
                      </b-button>
                      <b-button
                        variant="outline-danger"
                        v-b-modal.modal-delete-question
                        class="mx-1"
                      >
                        Soruyu Sil
                      </b-button>
                    </div>

                    <div v-if="selectedQuestion.teq_id > 0">
                      <hr class="mt-2" />
                      <b-alert
                        v-if="selectedQuestion.canceled"
                        variant="warning"
                        show
                        class="mt-2"
                      >
                        <h4 class="alert-heading">İptal Açıklaması</h4>
                        <div class="alert-body">
                          {{ selectedQuestion.cancel_description }}
                        </div>
                      </b-alert>

                      <div style="display: flex; justify-content: flex-end">
                        <b-button
                          variant="outline-warning"
                          @click="transferQuestionToPool()"
                          class="mx-1 mt-1"
                        >
                          Havuza Aktar
                        </b-button>
                        <b-button
                          v-if="selectedQuestion.canceled"
                          variant="outline-primary"
                          @click="showRemoveQuestionCancelModal()"
                          class="mx-1 mt-1"
                        >
                          İptali Kaldır
                        </b-button>
                        <b-button
                          v-else
                          variant="warning"
                          @click="showCancelQuestionModal()"
                          class="mx-1 mt-1"
                        >
                          İptal Et
                        </b-button>
                      </div>
                    </div>
                  </div>

                  <div
                    v-else
                    class="mt-3"
                    style="display: flex; justify-content: flex-end"
                  >
                    <b-button
                      variant="outline-primary"
                      @click="addQuestion(false)"
                      class="mx-1"
                    >
                      Havuza Ekle
                    </b-button>
                    <b-button
                      v-if="selectedExam"
                      variant="primary"
                      @click="addQuestion(true)"
                    >
                      Seçili Denemeye Ekle
                    </b-button>
                  </div>

                  <b-modal
                    id="modal-delete-question"
                    ref="modal-delete-question"
                    hide-footer
                    centered
                    title="Soruyu Sil"
                  >
                    <p class="m-1">
                      Seçili soru tamamen silinecek. Bu işlem geri alınamaz!
                    </p>

                    <hr style="margin: 20px 10px" />
                    <div>
                      <b-button
                        class="mr-1"
                        style="float: right"
                        variant="primary"
                        @click="$refs['modal-delete-question'].hide()"
                      >
                        Kapat
                      </b-button>
                      <b-button
                        class="mr-1"
                        style="float: right"
                        variant="danger"
                        @click="deleteSelectedQuestion()"
                      >
                        Sil
                      </b-button>
                    </div>
                  </b-modal>

                  <b-modal
                    ref="modal-cancel-question"
                    hide-footer
                    centered
                    title="Soruyu İptal Et"
                  >
                    <p>
                      Soru iptal edilecek ve kullanıcıların puanları tekrar
                      hesaplanacak.
                      <br />
                      İptal ettiğiniz soru denemeye sonradan girenlerde deneme
                      esnasında normal görünmekte ve deneme sonucunda herkes
                      için sorunun iptal edildiği belirtilmektedir.
                      <br />
                      Soruda değişiklik/düzeltme yaptıysanız lütfen açıklama
                      kısmında bunu kullanıcılara belirtin.
                    </p>
                    <label class="mt-1" for="solution-text"
                      >İptal sebibi açıklaması (maks 2-3 satır)</label
                    >
                    <b-form-textarea
                      id="solution-text"
                      placeholder="Zorunlu alan"
                      rows="2"
                      max-rows="8"
                      class="mb-1 mb-xl-0"
                      v-model="cancelDescription"
                    />
                    <hr style="margin: 20px 10px" />
                    <div>
                      <b-button
                        class="mr-1"
                        style="float: right"
                        variant="primary"
                        @click="$refs['modal-cancel-question'].hide()"
                      >
                        Kapat
                      </b-button>
                      <b-button
                        class="mr-1"
                        style="float: right"
                        variant="warning"
                        @click="cancelQuestion()"
                      >
                        İptal Et
                      </b-button>
                    </div>
                  </b-modal>

                  <b-modal
                    ref="modal-remove-cancel"
                    hide-footer
                    centered
                    title="Soru İptalini Kaldır"
                  >
                    <p class="m-1">
                      Soru iptali kaldırılacak ve kullanıcıların puanları
                      verdikleri cevaplar üzerinden tekrar hesaplanacak.
                    </p>

                    <hr style="margin: 20px 10px" />
                    <div>
                      <b-button
                        class="mr-1"
                        style="float: right"
                        variant="primary"
                        @click="$refs['modal-remove-cancel'].hide()"
                      >
                        Kapat
                      </b-button>
                      <b-button
                        class="mr-1"
                        style="float: right"
                        variant="danger"
                        @click="removeQuestionCancel()"
                      >
                        Kaldır
                      </b-button>
                    </div>
                  </b-modal>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </div>

    <div style="display: flex; justify-content: flex-end">
      <b-button
        v-if="selectedExam && !trialExamForResults"
        class="mr-1 mb-1"
        variant="outline-primary"
        @click="$refs['modal-html-tags'].show()"
      >
        HTML Etiketleri
      </b-button>
    </div>

    <b-modal
      id="modal-html-tags"
      ref="modal-html-tags"
      hide-footer
      centered
      no-close-on-backdrop
      size="lg"
      title="Çözüm alanı için HTML etiketleri"
    >
      <b-alert variant="warning" show class="mt-1">
        <h4 class="alert-heading">Önemli Hatırlatma!</h4>
        <div class="alert-body">
          - Aşağıda listelenen HTML etiketleri yeni satıra geçme sağladıkları
          için metin içerisine eklenirken yeni satıra geçişmeden yan yana
          yazılmalıdır! Daima çıktı ekranı (görünüm) dikkate alınmalıdır.
        </div>
      </b-alert>
      <b-row>
        <b-col md="6" class="mb-1">
          <pre>
            {{ getSampleHtml() }}
          </pre>
        </b-col>
        <b-col md="6" class="mb-1">
          <div v-html="getSampleHtml()" />
        </b-col>
      </b-row>

      <hr style="margin: 20px 10px" />
      <div style="display: flex; justify-content: flex-end">
        <b-button
          class="mr-1 mb-1"
          variant="outline-primary"
          @click="$refs['modal-html-tags'].hide()"
        >
          Kapat
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-transfer-exam-questions"
      ref="modal-transfer-exam-questions"
      hide-footer
      centered
      no-close-on-backdrop
      size="md"
      title="Sınav Sorularını Aktar"
    >
      <div style="overflow-y: scroll; height: 400px">
        <b-table
          responsive
          hover
          :items="examsForQuestionTransfer"
          :fields="questionTransferTableFields"
        >
          <template #cell(exam_name)="data">
            {{ data.item.exam_name }}
          </template>

          <template #cell(current_question_count)="data">
            <div style="min-width: 80px">
              {{ data.item.current_question_count }}
            </div>
          </template>

          <template #cell(action)="data">
            <b-button
              size="sm"
              variant="outline-primary"
              @click="transferAllQuestionsOfExam(data.item.exam_name)"
              style="min-width: 75px"
            >
              Aktar
            </b-button>
          </template>
        </b-table>
      </div>

      <hr style="margin: 20px 10px" />
      <div style="display: flex; justify-content: flex-end">
        <b-button
          class="mr-1 mb-1"
          variant="primary"
          @click="$refs['modal-transfer-exam-questions'].hide()"
        >
          Kapat
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store/index";
import {
  BCard,
  BCardText,
  BTable,
  BSpinner,
  BPagination,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BFormFile,
  BFormTextarea,
  BTabs,
  BTab,
  BImg,
  BAlert,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import ExamResult from "@/views/admin/AdminExamResult.vue";

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BSpinner,
    BPagination,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    flatPickr,
    BButton,
    BFormFile,
    BFormTextarea,
    vSelect,
    BTabs,
    BTab,
    BImg,
    ExamResult,
    BAlert,
    BBadge,
  },
  data() {
    return {
      loading: true,
      //
      trialExamForResults: null,
      //
      addExamMode: false,
      //
      examsPerPage: 5,
      examsCurrentPage: 1,
      trialExams: [],
      selectedExam: null,
      //
      poolQuestions: [],
      questionFilter: "",
      selectedSubject: null,
      //
      questionPoolTableFields: [
        { key: "order", label: "No" },
        { key: "title", label: "Ders", sortable: true },
        { key: "subtitle", label: "Konu", sortable: true },
        { key: "question_text", label: "Soru" },
        { key: "action", label: "" },
      ],
      questionPoolCurrentPage: 1,
      questionPoolPerPage: 5,
      //
      //
      questionListFilter: "",
      //
      questionsCurrentPage: 1,
      questionsPerPage: 5,
      //
      examItemName: "",
      examItemQuestionCount: 100,
      examItemDuration: 100,
      basicPartDescription: null,
      clinicalPartDescription: null,
      examItemCondition: "Yayınlanmadı",
      examConditionOptions: [
        "Yayınlanmadı",
        "Tüm Kullanıcılar",
        "TUS Analiz Özel",
      ],
      //
      questionsOfSelectedExam: [],
      examQuestionsTableFields: [
        { key: "order", label: "Sıra" },
        { key: "title", label: "Ders", sortable: true },
        { key: "subtitle", label: "Konu", sortable: true },
        { key: "question_text", label: "Soru" },
      ],
      //
      examsTableFields: [
        { key: "exam_name", label: "Sınav Adı", sortable: true },
        { key: "question_count", label: "Soru Sayısı", sortable: true },
        { key: "duration_in_minutes", label: "Süre (DK)", sortable: true },
        { key: "creation_time", label: "Oluşturulma Tarihi", sortable: true },
        { key: "condition", label: "Yayınlanma Durumu", sortable: true },
        { key: "participant_count", label: "Katılımcı", sortable: true },
        { key: "results", label: "Sonuçlar" },
      ],
      //
      questionTransferTableFields: [
        { key: "exam_name", label: "Sınav Adı" },
        { key: "current_question_count", label: "Soru Sayısı" },
        { key: "action", label: "" },
      ],
      //
      selectedQuestion: null,
      //
      tusFields: [],
      selectedFieldText: null,
      questionText: "",
      optionA: "",
      optionB: "",
      optionC: "",
      optionD: "",
      optionE: "",
      answer: "",
      solution: "",
      newImage: null,
      uploadedImageUrls: [],
      //
      cancelDescription: "",
    };
  },

  computed: {
    subjectList() {
      var subjectList = [];
      this.tusFields.forEach(function (field) {
        if (field.subtitle_id == 0) {
          subjectList.push(field);
        }
      });
      return subjectList;
    },

    questionsInOrderOfSelectedExam() {
      var cloneList = this.questionsOfSelectedExam.slice();
      cloneList.sort(function (q1, q2) {
        return q1.creation_time.localeCompare(q2.creation_time);
      });
      var orderCounter = 1;
      cloneList.forEach(function (question) {
        question["order"] = orderCounter++;
      });
      if (!this.questionListFilter) {
        return cloneList;
      }
      var filteredList = [];
      var filter = this.questionListFilter.toLocaleLowerCase("tr-TR");
      cloneList.forEach((question) => {
        if (
          (
            question.title +
            question.subtitle +
            question.question_text +
            question.option_a +
            question.option_b +
            question.option_c +
            question.option_d +
            question.option_e +
            question.solution
          )
            .toLocaleLowerCase("tr-TR")
            .includes(filter)
        ) {
          filteredList.push(question);
        }
      });
      return filteredList;
    },

    adminObject() {
      return JSON.parse(localStorage.getItem("adminObject"));
    },
    examsTotalRows() {
      return this.trialExams.length;
    },
    questionssTotalRows() {
      return this.questionsOfSelectedExam.length;
    },

    questionsInTable() {
      var orderCounter = 1;
      var questionsInTable = [];
      var titleId = this.selectedSubject ? this.selectedSubject.title_id : 0;
      var filter = this.questionFilter.toLocaleLowerCase("tr-TR");
      this.poolQuestions.forEach(function (question) {
        if (question.title_id == titleId || titleId == 0) {
          var allText =
            question.question_text +
            " " +
            question.option_a +
            " " +
            question.option_b +
            " " +
            question.option_c +
            " " +
            question.option_d +
            " " +
            question.option_e +
            " " +
            question.solution;
          if (!filter || allText.toLocaleLowerCase("tr-TR").includes(filter)) {
            questionsInTable.push(question);
          }
        }
      });
      questionsInTable.forEach(function (question) {
        question["order"] = orderCounter++;
      });
      return questionsInTable;
    },

    tusFieldOptions() {
      var options = [];
      this.tusFields.forEach(function (field) {
        if (field.subtitle_id > 0) {
          options.push(field.title + " -> " + field.subtitle);
        } else {
          options.push(field.title);
        }
      });
      return options;
    },

    tQuestionDistribution() {
      var list = [{ title: "TEMEL BİLİMLER", count: 0 }];
      this.questionsOfSelectedExam.forEach(function (question) {
        if (!question.clinical) {
          list[0].count++;
          var subject;
          list.forEach(function (sbj) {
            if (sbj.title == question.title) {
              subject = sbj;
            }
          });
          if (subject) {
            subject.count++;
          } else {
            subject = { title: question.title, count: 1 };
            list.push(subject);
          }
        }
      });
      for (var i = 1; i < list.length; i++) {
        list[i].title = this.toTitleCase(list[i].title);
      }
      return list;
    },

    kQuestionDistribution() {
      var list = [{ title: "KLİNİK BİLİMLER", count: 0 }];
      this.questionsOfSelectedExam.forEach(function (question) {
        if (question.clinical) {
          list[0].count++;
          var subject;
          list.forEach(function (sbj) {
            if (sbj.title == question.title) {
              subject = sbj;
            }
          });
          if (subject) {
            subject.count++;
          } else {
            subject = { title: question.title, count: 1 };
            list.push(subject);
          }
        }
      });
      for (var i = 1; i < list.length; i++) {
        list[i].title = this.toTitleCase(list[i].title);
      }
      return list;
    },

    examsForQuestionTransfer() {
      var exams = [];
      this.trialExams.forEach((exam) => {
        if (exam.current_question_count > 0) {
          exams.push({
            exam_name: exam.exam_name,
            current_question_count: exam.current_question_count,
          });
        }
      });
      return exams;
    },
  },

  watch: {
    questionListFilter: function (newValue) {
      if (newValue) {
        this.questionsCurrentPage = 1;
      }
    },
  },

  methods: {
    async transferAllQuestionsOfExam(examName) {
      this.$refs["modal-transfer-exam-questions"].hide();
      var payload = {
        from_exam_name: examName,
        to_exam_name: this.selectedExam.exam_name,
      };
      var response = await store.dispatch(
        "examsAdmin/transferExamQuestions",
        payload
      );
      var selectedExam = this.selectedExam;
      if (response) {
        await store.dispatch("examsAdmin/getTrialExams");
        this.trialExams = store.state.examsAdmin.trialExams;
        this.selectedExam = selectedExam;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı!",
            icon: "BellIcon",
            text: "Sorular seçili sınava aktarıldı.",
            variant: "success",
          },
        });
      } else {
        this.showError("Oluşan bir hatadan dolayı işlem gerçekleştirilemedi.");
      }
    },

    async transferQuestionToPool() {
      if (this.selectedExam && this.selectedQuestion) {
        var payload = {
          question_id: this.selectedQuestion.teq_id,
          exam_id: this.selectedExam.te_id,
        };
        var transfered = await store.dispatch(
          "examsAdmin/transferQuestionToPool",
          payload
        );
        if (transfered) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "İşlem Başarılı!",
              icon: "BellIcon",
              text: "Seçtiğiniz soru havuza aktarıldı.",
              variant: "success",
            },
          });
          this.showAddQuestion();
          this.poolQuestions = await store.dispatch(
            "examsAdmin/getPoolQuestions"
          );
          this.questionsOfSelectedExam = await store.dispatch(
            "examsAdmin/getQuestionsOfExam",
            this.selectedExam.te_id
          );
          this.selectedExam.current_question_count =
            this.questionsOfSelectedExam.length;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Uyarı!",
              icon: "BellIcon",
              text: "Bir sorun oluştu ve soru aktarılamadı!",
              variant: "danger",
            },
          });
        }
      }
    },

    async transferQuestionToTrialExamFromPool(question) {
      if (this.selectedExam && question) {
        var payload = {
          question_id: question.pq_id,
          exam_id: this.selectedExam.te_id,
        };
        var transfered = await store.dispatch(
          "examsAdmin/transferQuestionToTrialExamFromPool",
          payload
        );
        if (transfered) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "İşlem Başarılı!",
              icon: "BellIcon",
              text:
                "Seçtiğiniz soru " +
                this.toTitleCase(this.selectedExam.title) +
                " denemesine eklendi.",
              variant: "success",
            },
          });
          this.showAddQuestion();
          this.poolQuestions = await store.dispatch(
            "examsAdmin/getPoolQuestions"
          );
          this.questionsOfSelectedExam = await store.dispatch(
            "examsAdmin/getQuestionsOfExam",
            this.selectedExam.te_id
          );
          this.selectedExam.current_question_count =
            this.questionsOfSelectedExam.length;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Uyarı!",
              icon: "BellIcon",
              text: "Bir sorun oluştu ve soru taşınamadı!",
              variant: "danger",
            },
          });
        }
      }
    },

    async deleteSelectedQuestion() {
      this.$refs["modal-delete-question"].hide();
      var deleted = false;
      if (this.selectedQuestion.teq_id) {
        deleted = store.dispatch(
          "examsAdmin/deleteExamQuestion",
          this.selectedQuestion.teq_id
        );
      } else {
        deleted = store.dispatch(
          "examsAdmin/deletePoolQuestion",
          this.selectedQuestion.pq_id
        );
      }

      if (deleted) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı",
            icon: "BellIcon",
            text: "Seçili soru silindi.",
            variant: "success",
          },
        });

        if (this.selectedQuestion.teq_id) {
          this.questionsOfSelectedExam = await store.dispatch(
            "examsAdmin/getQuestionsOfExam",
            this.selectedExam.te_id
          );
          this.selectedExam.current_question_count =
            this.questionsOfSelectedExam.length;
        } else {
          var newPoolList = [];
          for (var question of this.poolQuestions) {
            if (question.pq_id != this.selectedQuestion.pq_id) {
              newPoolList.push(question);
            }
          }
          this.poolQuestions = newPoolList;
        }
        this.showAddQuestion();
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Hay aksi!",
            icon: "BellIcon",
            text: "Oluşan bir sorundan dolayı soru silinemedi!",
            variant: "danger",
          },
        });
      }
    },

    getTitleQuestionCount(titleId) {
      var count = 0;
      this.poolQuestions.forEach(function (question) {
        if (question.title_id == titleId || titleId == 0) {
          count++;
        }
      });
      return count;
    },

    showCancelQuestionModal() {
      this.$refs["modal-cancel-question"].show();
    },

    async cancelQuestion() {
      this.$refs["modal-cancel-question"].hide();
      if (!this.cancelDescription || this.cancelDescription.length < 10) {
        this.showError("Lütfen açıklama girin!");
        return;
      }
      var payload = {
        questionId: this.selectedQuestion.teq_id,
        cancelDescription: this.cancelDescription,
      };
      var response = await store.dispatch("examsAdmin/cancelQuestion", payload);
      if (response) {
        this.selectedQuestion.canceled = true;
        this.selectedQuestion.cancel_description = this.cancelDescription;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı!",
            icon: "BellIcon",
            text: "Soru iptal edildi.",
            variant: "success",
          },
        });
        this.$forceUpdate();
      } else {
        this.showError("Oluşan bir hatadan dolayı işlem gerçekleştirilemedi.");
      }
    },

    showRemoveQuestionCancelModal() {
      this.$refs["modal-remove-cancel"].show();
    },

    async removeQuestionCancel() {
      this.$refs["modal-remove-cancel"].hide();
      var response = await store.dispatch(
        "examsAdmin/removeQuestionCancel",
        this.selectedQuestion.teq_id
      );
      if (response) {
        this.selectedQuestion.canceled = false;
        this.selectedQuestion.cancel_description = null;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı!",
            icon: "BellIcon",
            text: "Soru iptali kaldırıldı.",
            variant: "success",
          },
        });
        this.$forceUpdate();
      } else {
        this.showError("Oluşan bir hatadan dolayı işlem gerçekleştirilemedi.");
      }
    },

    showExamResult(data) {
      this.trialExamForResults = data.item;
    },

    showAddExamCard() {
      this.examItemCondition = "Yayınlanmadı";
      this.selectedExam = null;
      this.addExamMode = true;
    },

    getOptionAnswerCount(option) {
      var count = 0;
      this.questionsOfSelectedExam.forEach(function (question) {
        if (question.answer == option) {
          count++;
        }
      });
      return count;
    },

    addNewExam() {
      this.examItemName = this.examItemName ? this.examItemName.trim() : "";
      if (
        !this.examItemName ||
        !this.examItemQuestionCount ||
        !this.examItemDuration
      ) {
        this.showError("Lütfen tüm alanları doldurun!");
        return;
      }

      if (
        this.isInt(this.examItemDuration) == false ||
        parseInt(this.examItemDuration) <= 0 ||
        parseInt(this.examItemDuration) > 360
      ) {
        this.showError("Sınav süresini hatalı girdiniz!");
        return;
      }

      if (
        this.isInt(this.examItemQuestionCount) == false ||
        parseInt(this.examItemQuestionCount) <= 0 ||
        parseInt(this.examItemQuestionCount) > 360
      ) {
        this.showError("Soru sayısını hatalı girdiniz!");
        return;
      }

      var tusAnalizExam = this.examItemCondition == "TUS Analiz Özel" ? 1 : 0;

      var self = this;
      var payload = {
        examName: this.examItemName,
        questionCount: this.examItemQuestionCount,
        durationInMinutes: this.examItemDuration,
        published: 0,
        tus_analiz_exam: tusAnalizExam,
        basicPartDescription: this.basicPartDescription,
        clinicalPartDescription: this.clinicalPartDescription,
      };
      store.dispatch("examsAdmin/addTrialExam", payload).then((response) => {
        if (response) {
          store.dispatch("examsAdmin/getTrialExams").then((response) => {
            self.trialExams = store.state.examsAdmin.trialExams;
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "İşlem Başarılı!",
              icon: "BellIcon",
              text: "Yeni sınav eklendi.",
              variant: "success",
            },
          });
        } else {
          self.showError("Bir hata oluştu ve sınav eklenemedi!");
        }
      });
    },

    onQuestionSelected(items) {
      this.selectedQuestion = items[0];
      if (this.selectedQuestion) {
        if (this.selectedQuestion.pq_id > 0) {
          if (this.$refs.examQuestionsTable) {
            this.$refs.examQuestionsTable.clearSelected();
          }
        } else {
          this.$refs.poolQuestionsTable.clearSelected();
        }
        this.questionText = this.selectedQuestion.question_text;
        this.optionA = this.selectedQuestion.option_a;
        this.optionB = this.selectedQuestion.option_b;
        this.optionC = this.selectedQuestion.option_c;
        this.optionD = this.selectedQuestion.option_d;
        this.optionE = this.selectedQuestion.option_e;
        this.answer = this.selectedQuestion.answer;
        this.solution = this.selectedQuestion.solution;
        this.uploadedImageUrls = [];

        this.selectedFieldText =
          this.selectedQuestion.title + " -> " + this.selectedQuestion.subtitle;
      } else {
        if (this.$refs.examQuestionsTable) {
          this.$refs.examQuestionsTable.clearSelected();
        }
        this.$refs.poolQuestionsTable.clearSelected();
        this.questionText = "";
        this.optionA = "";
        this.optionB = "";
        this.optionC = "";
        this.optionD = "";
        this.optionE = "";
        this.answer = "";
        this.solution = "";
        this.uploadedImageUrls = [];
      }
    },

    showAddQuestion() {
      this.selectedQuestion = null;
      this.questionText = "";
      this.optionA = "";
      this.optionB = "";
      this.optionC = "";
      this.optionD = "";
      this.optionE = "";
      this.answer = "";
      this.solution = "";
      this.uploadedImageUrls = [];
    },

    onExamSelected(items) {
      this.addExamMode = false;
      this.selectedQuestion = null;

      var exam = items[0];

      this.selectedExam = exam;
      this.examItemName = exam ? exam.exam_name : "";
      this.examItemQuestionCount = exam ? exam.question_count : 0;
      this.examItemDuration = exam ? exam.duration_in_minutes : 0;
      this.basicPartDescription = exam ? exam.basic_part_description : null;
      this.clinicalPartDescription = exam
        ? exam.clinical_part_description
        : null;

      if (exam) {
        this.examItemCondition = exam.published
          ? exam.tus_analiz_exam
            ? "TUS Analiz Özel"
            : "Tüm Kullanıcılar"
          : "Yayınlanmadı";

        this.questionsOfSelectedExam = [];
        store
          .dispatch("examsAdmin/getQuestionsOfExam", exam.te_id)
          .then((response) => {
            if (response) {
              this.questionsOfSelectedExam = response;
            }
          });
      }
    },

    isInt(value) {
      return (
        !isNaN(value) &&
        parseInt(Number(value)) == value &&
        !isNaN(parseInt(value, 10)) &&
        !(value + "").includes(".") &&
        !(value + "").includes(",")
      );
    },

    showError(errorMessage) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Uyarı!",
          icon: "BellIcon",
          text: errorMessage,
          variant: "danger",
        },
      });
    },

    async updateSelectedExam() {
      this.examItemName = this.examItemName ? this.examItemName.trim() : "";
      if (
        !this.examItemName ||
        !this.examItemQuestionCount ||
        !this.examItemDuration
      ) {
        this.showError("Lütfen tüm alanları doldurun!");
        return;
      }

      if (
        this.isInt(this.examItemDuration) == false ||
        parseInt(this.examItemDuration) <= 0 ||
        parseInt(this.examItemDuration) > 360
      ) {
        this.showError("Sınav süresini hatalı girdiniz!");
        return;
      }

      if (
        this.isInt(this.examItemQuestionCount) == false ||
        parseInt(this.examItemQuestionCount) <= 0 ||
        parseInt(this.examItemQuestionCount) > 360
      ) {
        this.showError("Soru sayısını hatalı girdiniz!");
        return;
      }

      var published = 0;
      var tusAnalizExam = 0;
      switch (this.examItemCondition) {
        case "Yayınlanmadı":
          break;
        case "Tüm Kullanıcılar":
          published = 1;
          break;
        case "TUS Analiz Özel":
          published = 1;
          tusAnalizExam = 1;
          break;
      }

      if (
        published &&
        this.examItemQuestionCount != this.questionsOfSelectedExam.length
      ) {
        this.showError(
          "Denemeyi yayınlayabilmeniz için belirtilen soru sayısı ile denemeye eklenen soru sayısı eşit olmalıdır!"
        );
        return;
      }

      var payload = {
        teId: this.selectedExam.te_id,
        examName: this.examItemName,
        questionCount: this.examItemQuestionCount,
        durationInMinutes: this.examItemDuration,
        published: published,
        tus_analiz_exam: tusAnalizExam,
        basicPartDescription: this.basicPartDescription,
        clinicalPartDescription: this.clinicalPartDescription,
      };
      var response = await store.dispatch(
        "examsAdmin/updateTrialExam",
        payload
      );
      if (response) {
        this.selectedExam.exam_name = this.examItemName;
        this.selectedExam.question_count = this.examItemQuestionCount;
        this.selectedExam.duration_in_minutes = this.examItemDuration;
        this.selectedExam.published = published;
        this.selectedExam.tus_analiz_exam = tusAnalizExam;
        this.selectedExam.basic_part_description = this.basicPartDescription;
        this.selectedExam.clinical_part_description =
          this.clinicalPartDescription;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı!",
            icon: "BellIcon",
            text: "Sınav bilgileri güncellendi",
            variant: "success",
          },
        });
      } else {
        this.showError("Bir hata oluştu ve bilgiler güncellenemedi!");
      }
    },

    deleteSelectedExam() {
      this.$refs["modal-delete-exam"].hide();
      var self = this;
      store
        .dispatch("examsAdmin/deleteTrialExam", this.selectedExam.te_id)
        .then((response) => {
          if (response) {
            var newList = [];
            self.trialExams.forEach(function (exam) {
              if (exam.te_id != self.selectedExam.te_id) {
                newList.push(exam);
              }
            });
            self.trialExams = newList;
            self.selectedExam = null;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "İşlem Başarılı!",
                icon: "BellIcon",
                text: "Seçtiğiniz sınav silindi",
                variant: "success",
              },
            });
          } else {
            self.showError("Bir hata oluştu ve sınav silinemedi!");
          }
        });
    },

    toTitleCase(str) {
      return !str
        ? ""
        : str
            .toLocaleLowerCase("tr-TR")
            .replace(
              /(?:^|\s|,|;|!|:|-|\.|\?)[a-z0-9ğçşüöı]/g,
              function (match) {
                return match.toLocaleUpperCase("tr-TR");
              }
            );
    },

    filterBy(option, label, search) {
      return (
        (label || "")
          .toLocaleLowerCase("tr-TR")
          .indexOf(search.toLocaleLowerCase("tr-TR")) > -1
      );
    },

    getHtmlWithImage(text) {
      if (!text) {
        return "";
      }
      if (text.toLocaleLowerCase("tr-TR").includes("script")) {
        return "Error!";
      }
      return text
        .replaceAll("[[", "<img src='")
        .replaceAll(
          "]]",
          "' style='margin: 0px auto; max-width: 96%; display: block; max-height: 640px;'/>"
        );
    },

    async updateSelectedQuestion() {
      this.questionText = this.getTrimmedText(this.questionText);
      this.optionA = this.getTrimmedText(this.optionA);
      this.optionB = this.getTrimmedText(this.optionB);
      this.optionC = this.getTrimmedText(this.optionC);
      this.optionD = this.getTrimmedText(this.optionD);
      this.optionE = this.getTrimmedText(this.optionE);
      this.answer = this.getTrimmedText(this.answer);
      this.solution = this.getTrimmedText(this.solution);

      if (
        !this.selectedFieldText ||
        !this.questionText ||
        !this.optionA ||
        !this.optionB ||
        !this.optionC ||
        !this.optionD ||
        !this.optionE ||
        !this.answer
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Eksik Var!",
            icon: "BellIcon",
            text: "Lütfen tüm zorunlu alanları doldurup doğru seçeneği işaretleyin!",
            variant: "danger",
          },
        });
        return;
      }

      if (
        this.optionA == this.optionB ||
        this.optionA == this.optionC ||
        this.optionA == this.optionD ||
        this.optionA == this.optionE ||
        this.optionB == this.optionC ||
        this.optionB == this.optionD ||
        this.optionB == this.optionE ||
        this.optionC == this.optionD ||
        this.optionC == this.optionE ||
        this.optionD == this.optionE
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Aynı seçenek var!",
            icon: "BellIcon",
            text: "Aynı seçenekten birden fazla eklediniz!",
            variant: "danger",
          },
        });
        return;
      }

      let self = this;

      var titleId = -1;
      var subtitleId = -1;
      this.tusFields.forEach(function (field) {
        if (
          self.selectedFieldText == field.title + " -> " + field.subtitle ||
          self.selectedFieldText == field.title + field.subtitle
        ) {
          titleId = field.title_id;
          subtitleId = field.subtitle_id;
        }
      });

      if (titleId < 0 || subtitleId < 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Hay aksi!",
            icon: "BellIcon",
            text: "Alan tespit edilemedi!",
            variant: "danger",
          },
        });
        return;
      }

      var payload = {
        questionId:
          this.selectedQuestion.teq_id > 0
            ? this.selectedQuestion.teq_id
            : this.selectedQuestion.pq_id,
        questionText: this.questionText,
        optionA: this.optionA,
        optionB: this.optionB,
        optionC: this.optionC,
        optionD: this.optionD,
        optionE: this.optionE,
        answer: this.answer,
        solution: this.solution,
        titleId: titleId,
        subtitleId: subtitleId,
      };

      var updated = false;
      if (this.selectedQuestion.teq_id > 0) {
        updated = await store.dispatch(
          "examsAdmin/updateExamQuestion",
          payload
        );
      } else {
        updated = await store.dispatch(
          "examsAdmin/updatePoolQuestion",
          payload
        );
      }
      if (updated) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı",
            icon: "BellIcon",
            text: "Soru güncellendi.",
            variant: "success",
          },
        });
        this.selectedQuestion.question_text = this.questionText;
        this.selectedQuestion.option_a = this.optionA;
        this.selectedQuestion.option_b = this.optionB;
        this.selectedQuestion.option_c = this.optionC;
        this.selectedQuestion.option_d = this.optionD;
        this.selectedQuestion.option_e = this.optionE;
        this.selectedQuestion.answer = this.answer;
        this.selectedQuestion.solution = this.solution;
        this.selectedQuestion.option_e = this.optionE;
        this.tusFields.forEach(function (field) {
          if (
            self.selectedFieldText == field.title + " -> " + field.subtitle ||
            self.selectedFieldText == field.title + field.subtitle
          ) {
            self.selectedQuestion.title_id = field.title_id;
            self.selectedQuestion.title = field.title;
            self.selectedQuestion.subtitle_id = field.subtitle_id;
            self.selectedQuestion.subtitle = field.subtitle;
          }
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Hay aksi!",
            icon: "BellIcon",
            text: "Oluşan bir sorundan dolayı soru güncellenemedi!",
            variant: "danger",
          },
        });
      }
    },

    getTrimmedText(text) {
      if (!text) {
        return "";
      }
      return text.trim();
    },

    async addQuestion(toSelectedExam) {
      this.questionText = this.getTrimmedText(this.questionText);
      this.optionA = this.getTrimmedText(this.optionA);
      this.optionB = this.getTrimmedText(this.optionB);
      this.optionC = this.getTrimmedText(this.optionC);
      this.optionD = this.getTrimmedText(this.optionD);
      this.optionE = this.getTrimmedText(this.optionE);
      this.answer = this.getTrimmedText(this.answer);
      this.solution = this.getTrimmedText(this.solution);

      if (
        !this.selectedFieldText ||
        !this.questionText ||
        !this.optionA ||
        !this.optionB ||
        !this.optionC ||
        !this.optionD ||
        !this.optionE ||
        !this.answer
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Eksik Var!",
            icon: "BellIcon",
            text: "Lütfen tüm zorunlu alanları doldurup doğru seçeneği işaretleyin!",
            variant: "danger",
          },
        });
        return;
      }

      if (
        this.optionA == this.optionB ||
        this.optionA == this.optionC ||
        this.optionA == this.optionD ||
        this.optionA == this.optionE ||
        this.optionB == this.optionC ||
        this.optionB == this.optionD ||
        this.optionB == this.optionE ||
        this.optionC == this.optionD ||
        this.optionC == this.optionE ||
        this.optionD == this.optionE
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Aynı seçenek var!",
            icon: "BellIcon",
            text: "Aynı seçenekten birden fazla eklediniz!",
            variant: "danger",
          },
        });
        return;
      }

      let self = this;

      var titleId = -1;
      var subtitleId = -1;
      this.tusFields.forEach(function (field) {
        if (
          self.selectedFieldText == field.title + " -> " + field.subtitle ||
          self.selectedFieldText == field.title + field.subtitle
        ) {
          titleId = field.title_id;
          subtitleId = field.subtitle_id;
        }
      });

      if (titleId < 0 || subtitleId < 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Hay aksi!",
            icon: "BellIcon",
            text: "Alan tespit edilemedi!",
            variant: "danger",
          },
        });
        return;
      }

      var added = false;

      if (toSelectedExam) {
        var payload = {
          trialExamId: this.selectedExam.te_id,
          questionText: this.questionText,
          optionA: this.optionA,
          optionB: this.optionB,
          optionC: this.optionC,
          optionD: this.optionD,
          optionE: this.optionE,
          answer: this.answer,
          solution: this.solution,
          titleId: titleId,
          subtitleId: subtitleId,
        };
        added = await store.dispatch("examsAdmin/addQuestionToExam", payload);
      } else {
        var pyld = {
          questionText: this.questionText,
          optionA: this.optionA,
          optionB: this.optionB,
          optionC: this.optionC,
          optionD: this.optionD,
          optionE: this.optionE,
          answer: this.answer,
          solution: this.solution,
          titleId: titleId,
          subtitleId: subtitleId,
        };
        added = await store.dispatch("examsAdmin/addQuestionToPool", pyld);
      }

      if (added) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı",
            icon: "BellIcon",
            text: toSelectedExam
              ? "Yazdığınız soru denemeye eklendi"
              : "Yazdığınız soru veritabanına eklendi.",
            variant: "success",
          },
        });
        if (toSelectedExam) {
          store
            .dispatch("examsAdmin/getQuestionsOfExam", self.selectedExam.te_id)
            .then((response) => {
              if (response) {
                self.questionsOfSelectedExam = response;
                self.selectedExam.current_question_count =
                  self.selectedExam.current_question_count + 1;
              }
            });
        } else {
          this.poolQuestions = await store.dispatch(
            "examsAdmin/getPoolQuestions"
          );
        }
        this.questionText = "";
        this.optionA = "";
        this.optionB = "";
        this.optionC = "";
        this.optionD = "";
        this.optionE = "";
        this.answer = "";
        this.solution = "";
        this.selectedFieldText = "";
        this.uploadedImageUrls = [];
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Hay aksi!",
            icon: "BellIcon",
            text: "Oluşan bir sorundan dolayı soru eklenemedi!",
            variant: "danger",
          },
        });
      }
    },
    uploadNewImage() {
      var file = this.newImage;
      if (file) {
        if (file.size > 5 * 1024 * 1024) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Uyarı!",
              icon: "BellIcon",
              text: "Seçilen dosya boyutu en fazla 5 MB olmalıdır!",
              variant: "danger",
            },
          });
          return;
        }
        if (
          !(
            file.type == "image/jpeg" ||
            file.type == "image/jpg" ||
            file.type == "image/png"
          )
        ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Hatalı format!",
              icon: "BellIcon",
              text: "Kabul edilen dosya formatı: JPG, JPEG, PNG",
              variant: "danger",
            },
          });
          return;
        }

        let self = this;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
          var imageBase64 = reader.result;
          store
            .dispatch("examsAdmin/uploadImage", imageBase64)
            .then((response) => {
              if (response) {
                self.newImage = null;
                self.uploadedImageUrls.push(response);
                self.$bvToast.toast("", {
                  title: `Fotograf Yüklendi`,
                  variant: `success`,
                  solid: true,
                });
              } else {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Hata oluştu!",
                    icon: "BellIcon",
                    text: "Oluşan bir sorundan dolayı yükleme işlemi gerçekleştirilemedi.",
                    variant: "danger",
                  },
                });
              }
            });
        };
        reader.onerror = function (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Hay Aksi!",
              icon: "BellIcon",
              text: "Bilinmeyen bir hata oluştu",
              variant: "danger",
            },
          });
        };
      }
    },
    getSampleHtml() {
      return `
<b>Kalın Yazı</b>
<br/>
<i>İtalik Yazı</i>

<br/>1. satır<br/>2. satır<br/>

Sırasız Liste
<ul>
  <li>Lorem</li>
  <li>ipsum</li>
  <li>dolor</li>
  <li>sit</li>
</ul>

Sıralı Liste
<ol>
  <li>lorem</li>
  <li>ipsum</li>
  <li>dolor</li>
  <li>sit</li>
</ol>

<h1>Başlık</h1>
<h2>Başlık</h2>
<h3>Başlık</h3>
<h4>Başlık</h4>
<h5>Başlık</h5>

<a href="https://tusbuddy.com">bağlantı metni</a>
`;
    },

    async pasteCopiedQuestion() {
      const copiedText = await navigator.clipboard.readText();
      if (copiedText && copiedText.includes("question_text")) {
        const questionJson = JSON.parse(copiedText);
        if (questionJson) {
          this.questionText = questionJson.question_text;
          this.optionA = questionJson.option_a;
          this.optionB = questionJson.option_b;
          this.optionC = questionJson.option_c;
          this.optionD = questionJson.option_d;
          this.optionE = questionJson.option_e;
          this.solution = questionJson.solution;

          var category = "";
          if (questionJson.subtitle_id > 0) {
            category = questionJson.title + " -> " + questionJson.subtitle;
          } else {
            category = questionJson.title;
          }
          if (this.tusFieldOptions.includes(category)) {
            this.selectedFieldText = category;
          }
          return;
        }
      }
      this.showError("Kopyalanmış soru bulunamadı!");
    },
  },

  async created() {
    let moduleId = "trial_exams";
    let moduleName = "TUS Denemeleri"; 
    if(JSON.parse(localStorage.getItem("adminObject"))[moduleId] != 1){
      this.$router.push("/erisim-kapali?moduleName="+moduleName);
      return; 
    } 

    await store.dispatch("examsAdmin/getTrialExams");
    this.trialExams = store.state.examsAdmin.trialExams;
    await store.dispatch("examsAdmin/getTusFields");
    this.tusFields = store.state.examsAdmin.tusFields;

    this.poolQuestions = await store.dispatch("examsAdmin/getPoolQuestions");

    this.loading = false;
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.max-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.max-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>
