<template>
  <div>
    <div v-if="loading" class="d-flex justify-content-center mb-1">
      <b-spinner label="Loading..." />
    </div>

    <div v-else>
      <b-card :title="trialExam.exam_name" style="min-height: 240px">
        <b-tabs style="margin-top: -8px">
          <b-tab title="Katılımcılar">
            <div style="width: 100%">
              <b-form-group
                label="Katılımcı Filtreleyin"
                label-for="inst"
                class="mt-1 mx-1"
              >
                <b-form-input
                  id="inst"
                  v-model="userFilter"
                  placeholder="Ad, soyad veya kullanıcı adı girin"
                  autocomplete="off"
                />
              </b-form-group>
            </div>

            <b-table
              responsive
              hover
              selectable
              class="odd-even-table"
              select-mode="single"
              @row-selected="onUserSelected"
              :per-page="resultsPerPage"
              :current-page="resultsCurrentPage"
              :items="filteredUserResults"
              :fields="userResultTableFields"
            >
              <template #cell(index)="data">
                <p
                  :class="
                    selectedUser && data.item.ter_id == selectedUser.ter_id
                      ? 'font-weight-bolder text-primary'
                      : ''
                  "
                  style="margin-bottom: 0"
                >
                  {{
                    (resultsCurrentPage - 1) * resultsPerPage + data.index + 1
                  }}
                </p>
              </template>

              <template #cell(name)="data">
                <p
                  :class="
                    selectedUser && data.item.ter_id == selectedUser.ter_id
                      ? 'font-weight-bolder text-primary'
                      : ''
                  "
                  style="margin-bottom: 0"
                >
                  {{ toTitleCase(data.item.name) }}
                </p>
              </template>

              <template #cell(surname)="data">
                <p
                  :class="
                    selectedUser && data.item.ter_id == selectedUser.ter_id
                      ? 'font-weight-bolder text-primary'
                      : ''
                  "
                  style="margin-bottom: 0"
                >
                  {{ toTitleCase(data.item.surname) }}
                </p>
              </template>

              <template #cell(user_name)="data">
                <p
                  :class="
                    selectedUser && data.item.ter_id == selectedUser.ter_id
                      ? 'font-weight-bolder text-primary'
                      : ''
                  "
                  style="margin-bottom: 0"
                >
                  {{ data.item.user_name }}
                </p>
              </template>

              <template #cell(title)="data">
                <p
                  :class="
                    selectedUser && data.item.ter_id == selectedUser.ter_id
                      ? 'font-weight-bolder text-primary'
                      : ''
                  "
                  style="margin-bottom: 0"
                >
                  {{ toTitleCase(data.item.title) }}
                </p>
              </template>

              <template #cell(delete_participation)="data">
                <b-button
                  :variant="'outline-danger'"
                  size="sm"
                  pill
                  @click="showDeleteParticipationModal(data.item)"
                >
                  <span class="align-middle">Kaldır</span>
                </b-button>
              </template>
            </b-table>

            <b-modal
              id="modal-delete-participation"
              ref="modal-delete-participation"
              hide-footer
              centered
              title="Katılımı Sil"
            >
              <p v-if="selectedUser" class="m-1">
                {{selectedUser.name + ' ' + selectedUser.surname}} adlı kullanıcının katılımı ve verdiği cevaplar tamamen silinecek.
              </p>

              <hr style="margin: 20px 10px" />
              <div>
                <b-button
                  class="mr-1"
                  style="float: right"
                  variant="primary"
                  @click="$refs['modal-delete-participation'].hide()"
                >
                  Kapat
                </b-button>
                <b-button
                  class="mr-1"
                  style="float: right"
                  variant="danger"
                  @click="deleteExamParticipationOfUser()"
                >
                  Sil
                </b-button>
              </div>
            </b-modal>

            <div style="display: flex; margin-top: 15px">
              <div class="font-bold" style="flex-grow: 1">
                Kişi sayısı: {{ filteredUserResults.length }}
              </div>
              <div>
                <b-pagination
                  v-model="resultsCurrentPage"
                  :total-rows="filteredUserResults.length"
                  :per-page="resultsPerPage"
                  :align="'center'"
                  class="mb-2"
                />
              </div>
            </div>

            <div>
              <p v-if="selectedUser">
                <user-result
                  :userResult="selectedUser"
                  :trialExamId="trialExam.te_id"
                ></user-result>
              </p>
            </div>
          </b-tab>

          <b-tab title="Sorular">
            <b-table
              responsive
              hover
              selectable
              select-mode="single"
              @row-selected="onQuestionSelected"
              :per-page="questionPerPage"
              :current-page="questionsCurrentPage"
              :items="examQuestionsWithStatistics"
              :fields="questionsTableFields"
            >
              <template #cell(id)="data">
                <p
                  :class="
                    selectedQuestion &&
                    data.item.teq_id == selectedQuestion.teq_id
                      ? 'font-weight-bolder text-primary'
                      : ''
                  "
                  style="margin-bottom: 0"
                >
                  {{ data.item.teq_id }}
                </p>
              </template>

              <template #cell(title)="data">
                <p
                  :class="
                    selectedQuestion &&
                    data.item.teq_id == selectedQuestion.teq_id
                      ? 'font-weight-bolder text-primary'
                      : ''
                  "
                  style="margin-bottom: 0"
                >
                  {{ toTitleCase(data.item.title) }}
                </p>
              </template>

              <template #cell(question_text)="data">
                <div
                  :class="
                    selectedQuestion &&
                    data.item.teq_id == selectedQuestion.teq_id
                      ? 'font-weight-bolder text-primary'
                      : ''
                  "
                  style="max-width: 900px"
                >
                  {{
                    data.value.length > 180
                      ? data.value.substring(0, 180) + "..."
                      : data.value
                  }}
                </div>
              </template>

              <template #cell(correct_percentage)="data">
                <p
                  :class="
                    selectedQuestion &&
                    data.item.teq_id == selectedQuestion.teq_id
                      ? 'font-weight-bolder text-primary'
                      : ''
                  "
                  style="margin-bottom: 0; text-align: center"
                >
                  % {{ data.item.correct_percentage }}
                </p>
              </template>

              <template #cell(favorite_count)="data">
                <p
                  :class="
                    selectedQuestion &&
                    data.item.teq_id == selectedQuestion.teq_id
                      ? 'font-weight-bolder text-primary'
                      : ''
                  "
                  style="margin-bottom: 0; text-align: center"
                >
                  {{ data.item.favorite_count }}
                </p>
              </template>
            </b-table>

            <div style="display: flex; margin-top: 15px">
              <div class="font-bold" style="flex-grow: 1"></div>
              <div>
                <b-pagination
                  v-model="questionsCurrentPage"
                  :total-rows="examQuestionsWithStatistics.length"
                  :per-page="questionPerPage"
                  align="center"
                  class="mb-2"
                />
              </div>
            </div>

            <div v-if="selectedQuestion" title="" style="padding: 1% 1%">
              <div style="padding-right: 10px">
                <div v-if="selectedQuestion">
                  <div
                    v-html="getSelectedQuestionText()"
                    style="margin-bottom: 20px; font-size: 1.15rem"
                  ></div>

                  <div
                    v-for="(option, index) in options"
                    :key="index"
                    style="margin-top: 10px; display: table"
                  >
                    <b-button
                      :variant="getOptionButtonClass(selectedQuestion, option)"
                      class="rounded-circle"
                      style="
                        padding: 6px;
                        height: 26px;
                        width: 26px;
                        margin-right: 12px;
                        display: table-cell;
                      "
                      size="sm"
                    >
                      {{ option }}
                    </b-button>
                    <div style="display: table-cell; width: 100%">
                      <p
                        :class="getOptionTextClass(selectedQuestion, option)"
                        style="font-size: 1.1rem; margin-bottom: 0px"
                      >
                        {{ getSelectedQuestionOptionText(option) }}
                      </p>
                      <div style="display: flex">
                        <b-progress
                          style="
                            flex-grow: 1;
                            margin-top: 10px;
                            height: 3px !important;
                          "
                          v-model="
                            getOptionSelectionDistributionPercentage(option)
                              .value
                          "
                          class="progress-bar-primary"
                          max="100"
                        >
                        </b-progress>
                        <div style="width: 50px; text-align: center">
                          <p style="font-size: 0.95rem; margin: 0 auto">
                            %{{
                              getOptionSelectionDistributionPercentage(option)
                                .value
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <b-alert variant="secondary" show style="margin-top: 15px">
                    <div class="alert-body" style="padding: 3px 2px 3px 15px">
                      <div style="display: flex">
                        <p style="flex-grow: 1">Boş bırakanlar</p>
                        <div style="width: 50px; text-align: center">
                          <p style="font-size: 0.95rem; margin: 0 auto">
                            %{{ getSelectionQuestionEmptyPercentage() }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </b-alert>
                </div>

                <b-card-text class="mt-3" style="white-space: pre-wrap">
                  <div
                    v-html="
                      '<strong>Çözüm / Açıklama</strong> <hr/>' +
                      getSelectedQuestionSolutionText()
                    "
                  ></div>
                </b-card-text>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import store from "@/store/index";
import {
  BCard,
  BCardText,
  BTable,
  BSpinner,
  BPagination,
  BRow,
  BCol,
  BProgress,
  BFormInput,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BFormFile,
  BFormTextarea,
  BAlert,
  BBadge,
  BTabs,
  BTab,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserResult from "@/views/admin/AdminUserExamResult.vue";

export default {
  components: {
    UserResult,
    BCard,
    BCardText,
    BTable,
    BSpinner,
    BPagination,
    BRow,
    BCol,
    BProgress,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    flatPickr,
    BButton,
    BFormFile,
    BFormTextarea,
    vSelect,
    BAlert,
    BBadge,
    BTabs,
    BTab,
  },
  props: {
    trialExam: null,
  },
  data() {
    return {
      loading: true,
      userFilter: "",
      trialExamUserResults: [],
      resultsPerPage: 10,
      resultsCurrentPage: 1,
      options: ["A", "B", "C", "D", "E"],
      userResultTableFields: [
        { key: "index", label: "Sıra" },
        { key: "name", label: "Ad" },
        { key: "surname", label: "Soyad" },
        { key: "user_name", label: "Kullanıcı Adı" },
        { key: "title", label: "Unvan", sortable: true },
        { key: "t_c", label: "Temel D", sortable: true },
        { key: "t_w", label: "Temel Y", sortable: true },
        { key: "k_c", label: "Klinik D", sortable: true },
        { key: "k_w", label: "Klinik Y", sortable: true },
        { key: "t_point", label: "Temel Puanı", sortable: true },
        { key: "k_point", label: "Klinik Puanı", sortable: true },
        { key: "delete_participation", label: "" },
      ],
      selectedUser: null,
      examQuestionsWithStatistics: [],
      questionPerPage: 10,
      questionsCurrentPage: 1,
      questionsTableFields: [
        { key: "id", label: "Soru ID" },
        { key: "title", label: "Ders", sortable: true },
        { key: "question_text", label: "Soru" },
        { key: "correct_percentage", label: "Doğru Oranı", sortable: true },
        { key: "favorite_count", label: "Favori Sayısı", sortable: true },
      ],
      selectedQuestion: null,
    };
  },

  computed: {
    adminObject() {
      return JSON.parse(localStorage.getItem("adminObject"));
    },
    filteredUserResults() {
      var filteredResults = [];
      var filter = this.userFilter.trim().toLocaleLowerCase("tr-TR");
      this.trialExamUserResults.forEach((result) => {
        if (!filter || filter.length == 0) {
          filteredResults.push(result);
        } else if (
          result.name.toLocaleLowerCase("tr-TR").includes(filter) ||
          result.surname.toLocaleLowerCase("tr-TR").includes(filter) ||
          (result.name + " " + result.surname)
            .toLocaleLowerCase("tr-TR")
            .includes(filter) ||
          result.user_name.toLocaleLowerCase("tr-TR").includes(filter)
        ) {
          filteredResults.push(result);
        }
      });
      return filteredResults;
    },
  },

  methods: {

    showDeleteParticipationModal(user){
      this.selectedUser = user;
      this.$refs["modal-delete-participation"].show();

    },
    async deleteExamParticipationOfUser() {
      this.$refs["modal-delete-participation"].hide();
      var payload = {
        adminId: this.selectedUser.user_id,
        trialExamId: this.selectedUser.trial_exam_id,
      };
      await store.dispatch("examsAdmin/deleteExamParticipationOfUser", payload);
      this.selectedUser = null;
      this.loadData();
      this.$forceUpdate();
    },

    getCorrectPercentage(question) {
      var participantCount = question.participant_count;
      var correctCount = 0;
      switch (question.answer) {
        case "A":
          correctCount = question.a_count;
          break;
        case "B":
          correctCount = question.b_count;
          break;
        case "C":
          correctCount = question.c_count;
          break;
        case "D":
          correctCount = question.d_count;
          break;
        case "E":
          correctCount = question.e_count;
          break;
      }
      return Math.round((100 * correctCount) / participantCount);
    },

    onQuestionSelected(items) {
      this.selectedQuestion = items[0];
    },

    async onUserSelected(items) {
      this.selectedUser = items[0];
    },

    getSelectedQuestionText() {
      if (!this.selectedQuestion) {
        return "Error!";
      }
      var text = this.selectedQuestion.question_text;
      if (text.toLocaleLowerCase("tr-TR").includes("script")) {
        return "Error!";
      }
      return (
        "<span style='font-weight: 600; color: #46b4ab;'>Soru (" +
        this.selectedQuestion.teq_id +
        ") : </span>" +
        text
          .replaceAll("[[", "<img src='")
          .replaceAll("\n", "<br/>")
          .replaceAll(
            "]]",
            "' style='margin: 0px auto; margin-top: 10px; margin-bottom: 10px; max-width: 96%; display: block; max-height: 640px;'/>"
          )
      );
    },

    getSelectedQuestionSolutionText() {
      if (!this.selectedQuestion) {
        return "Error!";
      }
      var solution = this.selectedQuestion.solution;
      if (solution.toLocaleLowerCase("tr-TR").includes("script")) {
        return "Error!";
      }
      return solution
        .replaceAll("[[", "<img src='")
        .replaceAll(
          "]]",
          "' style='margin: 0px auto; max-width: 96%; display: block; max-height: 340px;'/>"
        );
    },

    getSelectedQuestionOptionText(option) {
      var question = this.selectedQuestion;
      if (question && option) {
        switch (option) {
          case "A":
            return question.option_a;
          case "B":
            return question.option_b;
          case "C":
            return question.option_c;
          case "D":
            return question.option_d;
          case "E":
            return question.option_e;
        }
      }
      return "-";
    },

    getOptionSelectionDistributionPercentage(option) {
      var question = this.selectedQuestion;
      if (question) {
        var total = question.participant_count;
        if (total > 0) {
          switch (option) {
            case "A":
              return { value: Math.round((100 * question.a_count) / total) };
            case "B":
              return { value: Math.round((100 * question.b_count) / total) };
            case "C":
              return { value: Math.round((100 * question.c_count) / total) };
            case "D":
              return { value: Math.round((100 * question.d_count) / total) };
            case "E":
              return { value: Math.round((100 * question.e_count) / total) };
          }
        }
      }
      return { value: 0 };
    },

    getSelectionQuestionEmptyPercentage() {
      var question = this.selectedQuestion;
      if (question) {
        var total = question.participant_count;
        var empty =
          total -
          (question.a_count +
            question.b_count +
            question.c_count +
            question.d_count +
            question.e_count);
        return Math.round((100 * empty) / total);
      }
      return "";
    },

    getOptionButtonClass(selectedQuestion, option) {
      if (selectedQuestion.answer == option) {
        return "outline-primary";
      }
      return "outline-secondary";
    },
    getOptionTextClass(selectedQuestion, option) {
      if (selectedQuestion.answer == option) {
        return "font-weight-bolder text-primary";
      }
      return "";
    },

    toTitleCase(str) {
      return !str
        ? ""
        : str
            .toLocaleLowerCase("tr-TR")
            .replace(
              /(?:^|\s|,|;|!|:|-|\.|\?)[a-z0-9ğçşüöı]/g,
              function (match) {
                return match.toLocaleUpperCase("tr-TR");
              }
            );
    },

    async loadData() {
      var self = this;
      this.trialExamUserResults = await store.dispatch(
        "examsAdmin/getTrialExamUserResults",
        this.trialExam.te_id
      );
      this.examQuestionsWithStatistics = await store.dispatch(
        "examsAdmin/getExamQuestionsWithStatistics",
        this.trialExam.te_id
      );
      if (this.examQuestionsWithStatistics) {
        this.examQuestionsWithStatistics.forEach((stat) => {
          stat.correct_percentage = self.getCorrectPercentage(stat);
        });
      }
      this.loading = false;
    },
  },

  async created() {
    this.loadData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
